import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PersonalView } from '../../common/interfaces';

type EditViewModalProps = {
  view: PersonalView;
  viewPermission: string;
  onClose: () => void;
  onSave: (updatedLabel: string, viewPermission: string) => Promise<void>;
};

const EditViewModal: React.FC<EditViewModalProps> = ({
  view,
  viewPermission,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [editViewName, setEditViewName] = useState(view.label);
  const [checked, setChecked] = useState(viewPermission === "public");
  const [permission, setPermission] = useState<string>(viewPermission);
  const [isSaving, setIsSaving] = useState(false);
  const { colorMode } = useEuiTheme();
  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setPermission(e.target.checked ? "public" : "private");
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(editViewName, permission);
      // Modal will be closed by the parent component after successful save
    } catch (error) {
      console.error('Error saving view:', error);
      // Optionally, display an error message to the user
      setIsSaving(false);
    }
  };

  return (
    <EuiModal onClose={onClose} initialFocus="[name=editViewName]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>{t('Modals.EditView.Title')}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody className={colorMode.toLowerCase()}>
        <EuiForm>
          <EuiFormRow label={t('Modals.EditView.Text')} fullWidth>
            <EuiFieldText
              name="editViewName"
              value={editViewName}
              onChange={(e) => setEditViewName(e.target.value)}
              disabled={isSaving}
            />
          </EuiFormRow>
          <br />
          <EuiFormRow>
            <EuiCheckbox
              id={basicCheckboxId}
              label={t("Modals.SaveView.PublicView")}
              checked={checked}
              onChange={(e) => onChange(e)}
              disabled={isSaving}
            />
          </EuiFormRow>
          <EuiFormRow>
            <span style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflowWrap: 'break-word'
            }}>
              {t("Modals.SaveView.PublicViewInfo")}
            </span>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose} isDisabled={isSaving}>
          {t('Modals.EditView.Cancel')}
        </EuiButtonEmpty>
        <EuiButton onClick={handleSave} fill isLoading={isSaving}>
          {t('Modals.EditView.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditViewModal;
