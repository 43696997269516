import { EuiButtonIcon, EuiComboBox, EuiComboBoxOptionOption, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui';
import { Toast, ToastBody, ToastTitle } from '@fluentui/react-components';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ConfigurationService } from '../../services/ConfigurationService';
import { EasyContext } from '../EasyContext';
import { useToast } from '../../contexts/ToastContext';
import { useEasySearchProvider } from '../../contexts/EasySearchContext';

interface TagManagementProps {
  contactId: string;
  tags: string[];
}

export const TagManagementSection: React.FC<TagManagementProps> = ({
  contactId,
  tags,
}) => {
  const { t } = useTranslation();
  const { dispatchToast } = useToast();
  const { tenantConfig, accessToken } = useContext(EasyContext);
  const { selectedView, setSelectedView } = useEasySearchProvider();
  
  const configurationService = new ConfigurationService(accessToken);

  const [isAddingTag, setIsAddingTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState<EuiComboBoxOptionOption<string>[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const orgTags = tenantConfig?.orgTags ?? [];

  const notify = useCallback(
    (toastTitle: string, toastBody: string, toastIntent?: string) => {
      dispatchToast(
        <Toast>
          <ToastTitle>{toastTitle}</ToastTitle>
          <ToastBody>{toastBody}</ToastBody>
        </Toast>,
        { timeout: 3000, intent: toastIntent ?? 'success' },
      );
    },
    [dispatchToast],
  );

  useEffect(() => {
    const existingTags = mapExistingTags(tags, orgTags);
    setSelectedTags(existingTags);
  }, [tags]);

  const mapExistingTags = (tags: string[], orgTags: Array<{ id: string; label: string }>) => {
    return tags.map(tag => {
      const comboTag: EuiComboBoxOptionOption<string> = {
        id: orgTags.find((orgtag) => orgtag.label === tag)?.id,
        label: tag
      }
      return comboTag;
    })
  };

  const handleSaveTags = async () => {
    try {
      setIsAddingTag(true);

      const tagLabels = selectedTags?.map(tag => {
        return tag?.label;
      })

      const result = await configurationService.assignTagsToUser(contactId, tagLabels);

      if (result === true) {
        notify(
          t('Notifications.Tags.Title'),
          t('Notifications.Tags.Description', {
            label: tagLabels?.join(', '),
          }),
        );
      }
      else {
        notify(
          t('Notifications.Tags.Error.Title'),
          t('Notifications.Tags.Error.Description', {
            label: tagLabels?.join(', '),
          }),
          'error'
        );
      }

    } catch (error: unknown) {
      console.error('Error adding tag:', error);

      notify(
        t('Notifications.Tags.Error.Title'),
        (error as Error)?.message,
        'error'
      );
    } finally {
      setIsDisabled(true);
      setIsAddingTag(false);
      setSelectedView(selectedView);
    }
  }

  const handleAddTag = async (selectedOptions: EuiComboBoxOptionOption<string>[]) => {
    setSelectedTags(selectedOptions);
  }

  return (
    <EuiFlexGroup wrap gutterSize="xs">
      <EuiFlexGroup gutterSize="xs" alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiComboBox
            options={orgTags}
            placeholder="Select a tag"
            onChange={handleAddTag}
            onClick={() => { setIsDisabled(false); }}
            selectedOptions={selectedTags}
            isClearable={true}
            isDisabled={isDisabled}
          />
        </EuiFlexItem>
        {!isDisabled ? (
          isAddingTag ?
            <EuiLoadingSpinner size="s" /> : <EuiFlexItem grow={false}>
              <EuiButtonIcon size="s" iconType="save" aria-label={t("Button.Save")} onClick={() => handleSaveTags()} />
            </EuiFlexItem>
        ) : <EuiFlexItem grow={false}>
          <EuiButtonIcon size="s" iconType="pencil" aria-label={t("Button.Edit")} onClick={() => setIsDisabled(false)} />
        </EuiFlexItem>
        }
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
