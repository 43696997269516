// DetailsModal.tsx

import { withSearch } from '@elastic/react-search-ui';
import { ResultViewProps } from '@elastic/react-search-ui-views';
import {
  FluentProvider,
} from '@fluentui/react-components';
import { useRef, useState } from 'react';

import { EasyDetailCard } from '../cards/EasyDetailCard';
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiModal, EuiModalBody, EuiModalFooter, EuiSpacer, EuiTitle, useEuiTheme } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { AddContactToStaticView } from '../AddContactToStaticView';
import { FavoriteStar } from '../FavoriteStar';
import { SynchedContact } from '../SynchedContact';
import { SynchedContactRef } from '../../common/interfaces';

interface BaseDetailsModalProps {
  resultView: ResultViewProps;
  isOpen: boolean;
  onClose: () => void;
}

interface SearchProps {
  searchTerm: string | undefined;
  setSearchTerm: (term: string) => void;
  current: number | undefined;
  setCurrent: (current: number) => void;
}

type DetailsModalProps = BaseDetailsModalProps & Partial<SearchProps>;

const DetailsModalComponent: React.FC<DetailsModalProps> = ({
  resultView,
  isOpen,
  onClose,
  searchTerm,
  setSearchTerm,
  current,
  setCurrent,
}) => {
  const { t } = useTranslation();
  const { colorMode } = useEuiTheme();
  const [tagsChanged, setTagsChanged] = useState(false);
  const synchedContactRef = useRef<SynchedContactRef>(null);
  const onAddSynched = () => {
    if (synchedContactRef.current) {
      synchedContactRef.current.setIsLoading(true);
      synchedContactRef.current.addSynchedContact();
    }
  };
  const handleClose = () => {
    if (
      tagsChanged &&
      setSearchTerm &&
      setCurrent &&
      searchTerm !== undefined
    ) {
      // Reset to first page and re-execute current search
      //setCurrent(1);
      //setSearchTerm(searchTerm);
    }
    setTagsChanged(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      {isOpen && (
        <EuiModal aria-labelledby={resultView.result.id?.raw} onClose={() => { handleClose() }} className={'easy-details-modal ' + colorMode.toLowerCase()}>
          <EuiModalBody>
            <EuiSpacer size='m' />
            <EuiFlexGroup alignItems="flexStart">
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <h1>{resultView?.result?.display_name?.raw}</h1>
                </EuiTitle>
                {(resultView.result.job_title?.raw || resultView.result.company_name?.raw) && (
                  <EuiTitle size="xxxs" className='easy-result-card-subtitle'>
                    <h5>{resultView.result.company_name?.raw ? (
                      <>
                        {resultView.result.company_name?.raw}<br />{resultView.result.job_title?.raw}
                      </>) : (
                      resultView.result.job_title?.raw
                    )
                    }</h5>
                  </EuiTitle>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
            <FluentProvider>
              <EuiSpacer size='s' />
              <EasyDetailCard
                resultView={resultView}
                onTagsChange={() => setTagsChanged(true)}
              />
            </FluentProvider>
          </EuiModalBody>

          <EuiModalFooter className='easy-details-modal-footer'>
            <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem grow={false}>
                <FluentProvider className='easy-details-modal-fluentProvider'>
                  <EuiFlexGroup direction="row" gutterSize="s">
                    <EuiFlexItem>
                      <AddContactToStaticView contactId={resultView?.result?.id?.raw} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <FavoriteStar contactId={resultView?.result?.id?.raw} onAddSynched={onAddSynched} setIsLoading={synchedContactRef.current?.setIsLoading} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      {resultView?.result?.type?.raw !== 'personal' && (
                        <SynchedContact
                          ref={synchedContactRef}
                          contactId={resultView?.result?.id?.raw}
                          displayName={resultView?.result?.display_name?.raw}
                          synchedContact={resultView?.result}
                        />
                      )}
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </FluentProvider>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty iconType="cross" size="s" onClick={() => { handleClose() }}>{t("Button.Close")}</EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

export const DetailsModal = withSearch<BaseDetailsModalProps, SearchProps>(
  ({ searchTerm, setSearchTerm, current, setCurrent }) => ({
    searchTerm,
    setSearchTerm,
    current,
    setCurrent,
  }),
)(DetailsModalComponent);
