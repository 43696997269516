// EasySaveViewModal.tsx

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui';
import React, { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EasySaveViewModalProps = {
  onCloseModal: () => void;
  onSave: (viewName: string, permission: string) => Promise<void>;
};

const EasySaveViewModal: React.FC<EasySaveViewModalProps> = ({
  onCloseModal,
  onSave,
}) => {
  const { t } = useTranslation();
  const [viewName, setViewName] = useState('');
  const [permission, setPermission] = useState<string>("private");
  const [isSaving, setIsSaving] = useState(false);
  const { colorMode } = useEuiTheme();
  const [checked, setChecked] = useState(false);
  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });

  const handleSave = async () => {
    if (viewName.trim() === '') {
      // Optionally, show an error message for empty view name
      return;
    }
    setIsSaving(true);
    try {
      await onSave(viewName, permission);
      // The modal will be closed by the parent component after successful save
    } catch (error) {
      console.error('Error saving view:', error);
      // Optionally, show an error notification
    } finally {
      setIsSaving(false);
    }
  };


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setPermission(e.target.checked ? "public" : "private");
  };

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal} initialFocus="[name=viewName]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            {t('Modals.SaveView.Title')}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody className={colorMode.toLowerCase()}>
          <EuiForm>
            <EuiFormRow label={t('Modals.SaveView.Text')} className='easy-modal-label' fullWidth>
              <EuiFieldText
                name="viewName"
                value={viewName}
                onChange={(e) => setViewName(e.target.value)}
                fullWidth
                disabled={isSaving}
              />
            </EuiFormRow>
            <br />
            <EuiFormRow fullWidth>
              <EuiCheckbox
                id={basicCheckboxId}
                label={t("Modals.SaveView.PublicView")}
                checked={checked}
                onChange={(e) => onChange(e)}
                disabled={isSaving}
              />
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <p>
                {t("Modals.SaveView.PublicViewInfo")}
              </p>
            </EuiFormRow>
          </EuiForm>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onCloseModal} disabled={isSaving}>
            {t('Modals.SaveView.Cancel')}
          </EuiButtonEmpty>

          <EuiButton
            onClick={handleSave}
            fill
            isLoading={isSaving}
            disabled={isSaving}
          >
            {t('Modals.SaveView.Save')}
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );
};

export default EasySaveViewModal;
