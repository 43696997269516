export enum DeepLinkActionType {
  TeamsChat = 'TEAMSCHAT',
  TeamsCall = 'TEAMSCALL',
  TeamsVideoCall = 'TEAMSVIDEOCALL',
  PSTNCall = 'PSTNCALL',
  GoogleMaps = 'GOOGLEMAPPS',
  Email = 'EMAIL',
}

export enum EasyDirectoryViewOptions {
  PresenceView = 'PRESENCEVIEW',
  GroupedByTags = 'GROUPEDBYTAGS',
  Fullscreen = 'FULLSCREEN',
}

export enum ViewTypes {
  Standard,
  Static
}