import {
  Button,
  makeStyles,
  Toast,
  ToastBody,
  ToastTitle,
  tokens,
  Tooltip,
} from '@fluentui/react-components';
import { StarFilled, StarRegular } from '@fluentui/react-icons';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PersonalView, UserConfiguration } from '../common/interfaces';
import { useEasySearchProvider } from '../contexts/EasySearchContext';
import { useToast } from '../contexts/ToastContext';
import { ConfigurationService } from '../services/ConfigurationService';
import { EasyContext } from './EasyContext';
import { toPascalCase } from '../common/helpers';
import { ViewTypes } from '../common/enums';

interface FavoriteStarProps {
  contactId: string;
  displayName?: string;
  onFavoritesToggle?: () => void;
  onAddSynched?: () => void;
  setIsLoading?: (arg: boolean) => void;
}

const useClasses = makeStyles({
  iconColor: {
    color: tokens.colorPaletteGoldBorderActive,
  },
});

export const FavoriteStar: React.FC<FavoriteStarProps> = ({
  contactId,
  displayName,
  onAddSynched,
  setIsLoading
}) => {
  const classes = useClasses();
  const { t } = useTranslation();
  const { dispatchToast } = useToast();
  const { selectedView, setSelectedView } = useEasySearchProvider();

  const [favoritesIndex, setFavoritesIndex] = useState<number>();
  const [isFavorite, setIsFavorite] = useState(false);

  const { accessToken, userConfig, userId, dispatch } = useContext(EasyContext);

  const configurationService = new ConfigurationService(accessToken);

  useEffect(() => {
    // For an unknown reason, the keys of UserConfiguration gets loercased 
    const config: UserConfiguration = toPascalCase(userConfig);

    if (config?.Favorites) {
      const favorite = config.Favorites.find((f) => f === contactId);
      if (favorite) {
        setIsFavorite(favorite !== undefined);
        setFavoritesIndex(
          favorite
            ? config.Favorites.findIndex((f) => f === contactId)
            : undefined,
        );
      }
    }
  }, [userConfig?.Favorites, contactId]);

  const updateFavorites = async (favorites: string[]) => {
    try {
      if (favorites) {
        dispatch({
          type: 'UPDATE_FAVORITES',
          payload: favorites,
        });
      }
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };

  const addFavoritesContact = async () => {
    console.log('Starting add favorite, current state:', isFavorite);
    if (!contactId) {
      showToast(false, 'add');
      return;
    }

    try {
      const result = await configurationService.postFavorite(userId, contactId);

      if (result) {
        setIsFavorite(true);
        updateFavorites(result.Favorites ?? [])
        showToast(true, 'add', displayName);
        if (setIsLoading) {
          setIsLoading(true);
        }
        if (onAddSynched) {
          onAddSynched();
        }
      } else {
        showToast(false, 'add');
      }
    } catch (error) {
      console.error('Error adding favorite:', error);
      showToast(false, 'add');
    }
  };

  const removeFavoritesContact = async () => {

    if (favoritesIndex === undefined) {
      showToast(false, 'remove');
      return;
    }

    try {
      const result = await configurationService.deleteFavorite(
        userId,
        favoritesIndex,
      );
      if (result) {
        setIsFavorite(false);
        setFavoritesIndex(undefined);

        updateFavorites(result.Favorites ?? []);
        await handleFavouritesView(result.Favorites ?? []);
        showToast(true, 'remove', displayName);
      } else {
        showToast(false, 'remove');
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
      showToast(false, 'remove');
    }
  };

  const handleFavouritesView = async (favouriteIds: string[]) => {
    if (selectedView?.id === 'favourites_view') {
      const favouritesView: PersonalView = {
        id: 'favourites_view',
        viewType: ViewTypes.Standard,
        label: t('Views.Dropdown.FavoritesText'),
        searchTemplate: JSON.stringify({
          searchTerm: '',
          filters: [
            {
              field: 'id',
              values: favouriteIds,
              type: 'any',
            },
          ],
        }),
      };

      setSelectedView(favouritesView);
    }
  };

  const showToast = (
    success: boolean,
    action: 'add' | 'remove',
    label?: string,
  ) => {
    if (success) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {action === 'add'
              ? t('Notifications.Titles.View.Favorites.Added')
              : t('Notifications.Titles.View.Favorites.Removed')}
          </ToastTitle>
          <ToastBody>
            {action === 'add'
              ? t('Notifications.Contents.View.Favorites.Added', { label: label })
              : t('Notifications.Contents.View.Favorites.Removed', {
                label: label,
              })}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'success' },
      );
    } else {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('Notifications.Titles.View.Favorites.Error')}
          </ToastTitle>
          <ToastBody>
            {t('Notifications.Contents.View.Favorites.Error')}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'error' },
      );
    }
  };

  return (
    <Tooltip
      content={
        isFavorite ? t('Main.Card.RemoveFavorite') : t('Main.Card.AddFavourite')
      }
      relationship="description"
    >
      <Button
        appearance="transparent"
        size="small"
        onClick={isFavorite ? removeFavoritesContact : addFavoritesContact}
        icon={
          isFavorite ? (
            <StarFilled className={classes.iconColor} />
          ) : (
            <StarRegular className={classes.iconColor} />
          )
        }
      />
    </Tooltip>
  );
};
