import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewPermissions } from '../../common/interfaces';

type UpdateViewModalProps = {
  onClose: () => void;
  onUpdate: (permission: string) => void;
  viewPermission: string;
};

const UpdateViewModal: React.FC<UpdateViewModalProps> = ({
  onClose,
  onUpdate,
  viewPermission
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(viewPermission === "public");
  const [permission, setPermission] = useState<string>(viewPermission);
  const [isSaving, setIsSaving] = useState(false);
  const basicCheckboxId = useGeneratedHtmlId({ prefix: 'basicCheckbox' });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setPermission(e.target.checked ? "public" : "private");
  };

  const handleUpdate = async () => {
    setIsSaving(true);
    try {
      await onUpdate(permission);
      // The modal will be closed by the parent component after successful save
    } catch (error) {
      console.error('Error saving view:', error);
      // Optionally, show an error notification
    } finally {
      setIsSaving(false);
    }
  };

  const { colorMode } = useEuiTheme();
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Modals.UpdateView.Title')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody className={colorMode.toLowerCase()}>
        {t('Modals.UpdateView.Text')}
        <br />
        <br />
        <br />
        <EuiFormRow fullWidth>
          <EuiCheckbox
            id={basicCheckboxId}
            label={t("Modals.SaveView.PublicView")}
            checked={checked}
            onChange={(e) => onChange(e)}
            disabled={isSaving}
          />
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <p>
            {t("Modals.SaveView.PublicViewInfo")}
          </p>
        </EuiFormRow>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>
          {t('Modals.UpdateView.Cancel')}
        </EuiButtonEmpty>
        <EuiButton color="danger"
          disabled={isSaving} onClick={handleUpdate}>
          {t('Modals.UpdateView.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default UpdateViewModal;
