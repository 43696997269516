import axios, { AxiosError } from 'axios';

import config from '../common/config';
import {
  AccessToken,
  EasyTag,
  PersonalView,
  SharedMailbox,
  UserConfiguration,
} from '../common/interfaces';

export class ConfigurationService {
  constructor(access_token?: string) {
    if (access_token !== undefined && access_token !== null) {
      if (access_token.startsWith('Bearer ')) {
        this.access_token = access_token;
      } else {
        this.access_token = 'Bearer ' + access_token;
      }
    }
  }

  access_token: string | undefined;

  async importPrivateContacts(
    tenantId: string,
    userId: string,
    mailbox: string,
  ): Promise<SharedMailbox[] | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/import/privateMailbox',
        {
          TenantId: tenantId,
          UserId: userId,
          PersonalMailboxId: mailbox,
        },
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.sharedMailboxes;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getUserConfiguration(
    userId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.get(
        config.apiEndpoint + '/easydirectory/configurations/' + userId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postUserConfiguration(
    userId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/configurations/' + userId,
        {},
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updateDefaultView(
    userId: string,
    viewId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.patch(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/default/' +
        viewId,
        {},
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postSharedMailbox(
    userId: string,
    tenantId: string,
    mailbox: SharedMailbox,
    token: AccessToken
  ): Promise<UserConfiguration | unknown> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/admin/import/personalMailbox',
        {
          TenantId: tenantId,
          UserId: userId,
          PersonalMailboxId: mailbox.address,
        },
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log('Status:', axiosError.response.status);
        console.log('Data:', axiosError.response.data);
        return axiosError.response.status;
      } else if (axiosError.request) {
        console.log('Request wurde gemacht aber keine Antwort erhalten');
      } else {
        console.log('Error:', axiosError.message);
      }
      return null;
    }
  }
  async deleteSharedMailbox(
    userId: string,
    tenantId: string,
    mailboxId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/delete/mailbox/personal',
        {
          TenantId: tenantId,
          UserId: userId,
          Mailbox: mailboxId,
        },
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updatePersonalView(
    userId: string,
    updatedView: PersonalView,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.patch(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/personalView/' + updatedView.id,
        updatedView,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postPersonalView(
    userId: string,
    view: PersonalView,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/personalViews',
        view,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deletePersonalView(
    userId: string,
    viewId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/personalViews/' +
        viewId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postFavorite(
    userId: string,
    favoriteId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/favorites',
        favoriteId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deleteFavorite(
    userId: string,
    favoriteId: number,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/favorites/' +
        favoriteId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postSynchedUser(
    userId: string, contactIds: string[], token: AccessToken,
  ): Promise<string[]> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/synchedContacts',
        contactIds,
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getTenantConfiguration(): Promise<UserConfiguration | null> {
    try {
      const response = await axios.get(
        config.apiEndpoint + '/easydirectory/admin/configuration/',
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deleteSynchedUser(
    userId: string, synchedContactId: string, token: AccessToken,
  ): Promise<number> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
        '/easydirectory/configurations/' +
        userId +
        '/synchedContacts/' +
        synchedContactId,
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.status;
    } catch (e) {
      console.log(e);
      return 401;
    }
  }

  async getTagManagingPermission(): Promise<boolean> {
    try {
      const response = await axios.get<{ result: boolean }>(
        config.apiEndpoint + '/easydirectory/permission/tags',
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async DeleteTag(tag: EasyTag, TenantId: string): Promise<boolean> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/tags/delete',
        {
          TenantId: TenantId,
          TagToDelete: tag,
        },
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async RenameTag(
    oldTag: EasyTag,
    newTag: EasyTag,
    tenantId: string,
  ): Promise<boolean> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/tags/rename',
        { TenantId: tenantId, OldTag: oldTag, NewTag: newTag },
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async patchOrgTags(tags: EasyTag[], tenantId: string): Promise<boolean> {
    try {
      const response = await axios.patch(
        config.apiEndpoint + '/easydirectory/admin/' + tenantId + '/orgTags',
        tags,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async assignTagsToUser(contactId: string, tags: string[]): Promise<boolean> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/contacts/' + contactId + '/tags',
        tags,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
