import { SearchBox, withSearch } from '@elastic/react-search-ui';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EasyContext } from '../EasyContext';
import { useEasySearchProvider } from '../../contexts/EasySearchContext';
import { EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui';
import EasyCustomView from './EasyCustomView';
import { SortDirection, SortOption } from '@elastic/search-ui';

interface EasySearchHeaderProps {
  setSort: (sort: SortOption[] | string, sortDirection: SortDirection) => void;
}

const EasySearchHeader: React.FC<EasySearchHeaderProps> = ({
  setSort = () => { },
}) => {
  const { t } = useTranslation();
  const { userConfig } = useContext(EasyContext);
  const { setSelectedView } = useEasySearchProvider();

  const handleSearchSubmit = useCallback(
    (searchTerm: string) => {
      // Setze selectedView nur zurück, wenn eine Suche durch das Suchfeld ausgelöst wurde
      if (searchTerm) {
        setSort('', '');
        setSelectedView(undefined);
      }
    },
    [setSelectedView]
  );

  const CustomEasySearchView = (props: any) => {
    const { value, onChange, onSubmit } = props;
    return (
      <div className="euiFlexItem css-9sbomz-euiFlexItem-grow-1">

        <form
          role="combobox"
          aria-expanded="false"
          aria-haspopup="listbox"
          aria-labelledby="downshift-1-label"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearchSubmit(value);
            onSubmit(e);
          }}
        >
          <div className="sui-search-box">
            <div className="sui-search-box__wrapper">
              <input
                aria-autocomplete="list"
                aria-labelledby="downshift-1-label"
                autoComplete="off"
                id="downshift-1-input"
                data-transaction-name="search input"
                placeholder={t("Placeholder.Search")}
                className="sui-search-box__text-input"
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            </div>
            <input
              data-transaction-name="search submit"
              type="submit"
              className="button sui-search-box__submit"
              value={t("Button.Search")}
            />
          </div>
        </form>
      </div>
    )
  };

  return (
    <EuiPanel paddingSize="m">
      <EuiFlexGroup direction="row" alignItems="center" wrap={false}>
        <EuiFlexItem>{userConfig && <EasyCustomView />}</EuiFlexItem>
        <EuiFlexItem>
          <SearchBox
            autocompleteSuggestions={true}
            debounceLength={0}
            view={CustomEasySearchView}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};


export default withSearch<
  EasySearchHeaderProps,
  {
    setSort: (
      sort: SortOption[] | string,
      sortDirection: SortDirection,
    ) => void;
  }
>(
  ({
    setSort
  }) => ({
    setSort
  }),
)(EasySearchHeader);


//export default EasySearchHeader;
