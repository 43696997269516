import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLogo,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider';
import { UserInfo } from '@microsoft/teamsfx';
import { useCallback, useEffect, useReducer } from 'react';

import { loginAction } from '../auth/login';
import { TeamsUserCredentialContext } from '../auth/singletonContext';
import config from '../common/config';
import { EasyState } from '../common/interfaces';
import AppContent from '../components/AppContent';
import { EasyContext } from '../components/EasyContext';
import { ConfigurationService } from '../services/ConfigurationService';
import { SubscriptionService } from '../services/SubscriptionService';
import { Spinner } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

export interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: EasyState, action: Action): EasyState => {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return { ...state, accessToken: action.payload };
    case 'SET_SIGNED_IN':
      return { ...state, isSignedIn: true };
    case 'SET_USER_INFO':
      return {
        ...state,
        tenantId: action.payload.tenantId,
        userId: action.payload.objectId,
        userDisplayName: action.payload.displayName,
      };
    case 'SET_SUBSCRIPTIONS':
      return { ...state, subscriptions: action.payload };
    case 'SET_USER_CONFIG':
      return { ...state, userConfig: action.payload };
    case 'SET_TENANT_CONFIG':
      return { ...state, tenantConfig: action.payload };
    case 'SET_LOADED':
      return { ...state, isLoaded: true };
    case 'SET_SEAT_ASSIGNED':
      return { ...state, isSeatAssigned: true };
    case 'SET_SEAT_ASSIGNMENT_ERROR':
      return { ...state, seatAssignmentError: action.payload };
    case 'UPDATE_FAVORITES':
      return {
        ...state,
        userConfig: state.userConfig
          ? { ...state.userConfig, Favorites: action.payload }
          : state.userConfig,
      };
    case 'UPDATE_SHARED_MAILBOXES':
      return {
        ...state,
        userConfig: state.userConfig
          ? { ...state.userConfig, SharedMailboxes: action.payload }
          : state.userConfig,
      };
    case 'UPDATE_SYNCHED_CONTACTS':
      return {
        ...state,
        userConfig: state.userConfig
          ? { ...state.userConfig, SynchedContacts: action.payload }
          : state.userConfig,
      };
    case 'UPDATE_TAGS':
      return {
        ...state,
        tenantConfig: state.tenantConfig
          ? { ...state.tenantConfig, orgTags: action.payload }
          : state.tenantConfig,
      };
    default:
      return state;
  }
};

export const DefaultLayout = () => {
  const [state, dispatch] = useReducer(reducer, {
    accessToken: '',
    tenantId: '',
    userId: '',
    userDisplayName: '',
    isSignedIn: false,
    userConfig: undefined,
    subscriptions: [],
    isLoaded: false,
    isSeatAssigned: false,
    seatAssignmentError: '',
    dispatch: () => { },
  });

  const credential = TeamsUserCredentialContext.getInstance().getCredential();

  if (!credential) {
    throw new Error('TeamsFx SDK is not initialized.');
  }

  const { t } = useTranslation();
  const loginFx = useCallback(async () => {
    try {
      let token = (await credential.getToken(config.defaultScopes))?.token;
      if (!token) {
        await credential.login(config.defaultScopes);
        Providers.globalProvider = new TeamsFxProvider(
          credential,
          config.defaultScopes,
        );
        Providers.globalProvider.setState(ProviderState.SignedIn);
        dispatch({ type: 'SET_SIGNED_IN' });
        token = (await credential.getToken(config.defaultScopes))?.token;
      }
      if (token) {
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: token });
      }
      dispatch({ type: 'SET_LOADED' });
    } catch (e) {
      console.error('Error during login:', e);
      dispatch({ type: 'SET_LOADED' }); // Ensure loading state is updated even if login fails
      loginAction(config.defaultScopes);
    }
  }, [credential]);

  useEffect(() => {
    if (state.isSignedIn && state.accessToken) {
      credential
        .getUserInfo()
        .then(async (userInfo: UserInfo) => {
          dispatch({ type: 'SET_USER_INFO', payload: userInfo });

          const subscriptionService = new SubscriptionService(
            state.accessToken,
          );

          const configurationService = new ConfigurationService(
            state.accessToken,
          );

          try {
            const [subscriptions, config, tenantConfig, isTagManager] =
              await Promise.all([
                subscriptionService.getSubscriptions(),
                configurationService.getUserConfiguration(userInfo.objectId),
                configurationService.getTenantConfiguration(),
                configurationService.getTagManagingPermission(),
              ]);

            if (subscriptions) {
              dispatch({ type: 'SET_SUBSCRIPTIONS', payload: subscriptions });
            }

            if (config) {
              dispatch({ type: 'SET_USER_CONFIG', payload: config });
            } else {
              const newConfig =
                await configurationService.postUserConfiguration(
                  userInfo.objectId,
                );

              if (newConfig) {
                dispatch({ type: 'SET_USER_CONFIG', payload: newConfig });
              }
            }

            if (tenantConfig) {
              dispatch({ type: 'SET_TENANT_CONFIG', payload: tenantConfig });
            }

            if (isTagManager) {
              dispatch({ type: 'SET_IS_TAG_MANAGER', payload: isTagManager });
            }

            if (subscriptions && subscriptions.length > 0) {
              var activeSubs = subscriptions.filter(
                (s) => s.offer_id.startsWith('easydirectory') && s.state === 3,
              );

              if (activeSubs && activeSubs.length > 0) {
                const subscriptionId = activeSubs[0].subscription_id;

                const seatRequestData = {
                  user_id: userInfo.objectId,
                  tenant_id: userInfo.tenantId,
                  emails: [userInfo.preferredUserName],
                  user_name: userInfo.displayName,
                };

                const seatResult = await subscriptionService.enterTurnstile(
                  subscriptionId,
                  seatRequestData,
                );

                if (
                  seatResult.result_code === 'seat_provided' &&
                  seatResult.seat.redeemed_utc
                ) {
                  dispatch({ type: 'SET_SEAT_ASSIGNED' });
                } else {
                  dispatch({
                    type: 'SET_SEAT_ASSIGNMENT_ERROR',
                    payload: seatResult.result_code,
                  });
                }
              }
            } else {
              dispatch({
                type: 'SET_SEAT_ASSIGNMENT_ERROR',
                payload: 'No subscriptions available.',
              });
            }

            const sharedMailboxes =
              await configurationService.importPrivateContacts(
                userInfo.tenantId,
                userInfo.objectId,
                userInfo.preferredUserName,
              );

            dispatch({
              type: 'UPDATE_SHARED_MAILBOXES',
              payload: sharedMailboxes,
            });
          } catch (error) {
            console.error(
              'Error during data fetching or seat assignment:',
              error,
            );
            dispatch({
              type: 'SET_SEAT_ASSIGNMENT_ERROR',
              payload: 'An error occurred during initialization.',
            });
          }

          dispatch({ type: 'SET_LOADED' });
        })
        .catch((e) => {
          console.error('Error fetching user info:', e);
          dispatch({ type: 'SET_LOADED' });
        });
    }
  }, [state.isSignedIn, credential, state.accessToken]);

  useEffect(() => {
    credential
      .getToken(config.defaultScopes)
      .then((token) => {
        if (token) {
          Providers.globalProvider = new TeamsFxProvider(
            credential,
            config.defaultScopes,
          );
          Providers.globalProvider.setState(ProviderState.SignedIn);
          dispatch({ type: 'SET_SIGNED_IN' });
          dispatch({ type: 'SET_ACCESS_TOKEN', payload: token.token });
        } else {
          console.log('No token, calling login...');
          loginFx();
        }
      })
      .catch((e) => {
        console.error('Currently not signed in:', e);
        dispatch({ type: 'SET_LOADED' });
        loginFx();
      });
  }, [credential, loginFx]);

  return state.isLoaded ? (
    state.isSignedIn ? (
      state.isSeatAssigned ? (
          <EasyContext.Provider
            value={{ ...state, isLoaded: state.isLoaded, dispatch }}
          >
            <AppContent />
          </EasyContext.Provider>
      ) : (
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <EuiSpacer size="xxl" />
            <EuiSpacer size="xxl" />
            <EuiPanel paddingSize="l">
              <EuiTitle size="s">
                <h2>Access Denied</h2>
              </EuiTitle>
              <EuiSpacer size="m" />
              <EuiText>
                <p>
                  {state.seatAssignmentError
                    ? `Unable to assign seat: ${state.seatAssignmentError}`
                    : 'No seats are currently available. Please try again later.'}
                </p>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    ) : (
      <EuiFlexGroup justifyContent="spaceAround">
        <EuiFlexItem grow={false}>
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xxl" />
          <EuiPanel paddingSize="l">
            <EuiHeaderLogo iconType="TCF_EasyDirectory_Icon_RGB.png" href="#">
              Easy Directory
            </EuiHeaderLogo>
            <EuiSpacer size="m" />
            <EuiTitle size="s">
              <h2>Login</h2>
            </EuiTitle>
            <EuiSpacer size="m" />
            <EuiText>
              <p>
                Please sign in with your Microsoft Account to use Easy Directory
              </p>
              <EuiButton onClick={() => loginAction(config.defaultScopes)}>
                Login
              </EuiButton>
            </EuiText>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  ) : (
    <EuiFlexGroup justifyContent="spaceAround">
      <EuiFlexItem grow={false}>
        <EuiSpacer size="xxl" />
        <EuiSpacer size="xxl" />
        <Spinner size="huge" labelPosition="below" /> 
        {/* label={t("PleaseWait")} */}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
