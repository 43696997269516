import {
  EuiButton,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PersonalView } from '../common/interfaces';
import { EasyContext } from './EasyContext';
import { ViewTypes } from '../common/enums';
import { ConfigurationService } from '../services/ConfigurationService';
import { Toast, ToastTitle, ToastBody, Button, Tooltip } from '@fluentui/react-components';
import { useToast } from '../contexts/ToastContext';
import { Guid } from '../common/helpers';
import { useEasySearchProvider } from '../contexts/EasySearchContext';
import { AddCircleRegular, SubtractCircleRegular } from '@fluentui/react-icons';

interface SaveStaticViewPopoverProps {
  contactId: string;
}

export const AddContactToStaticView: React.FC<SaveStaticViewPopoverProps> = memo(
  ({
    contactId
  }) => {
    const { t } = useTranslation();
    const { accessToken, userId, userConfig, dispatch, tenantConfig } = useContext(EasyContext);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedOptions, setSelected] = useState<EuiComboBoxOptionOption<string>[]>();
    const [staticViewOptions, setStaticViewOptions] = useState<EuiComboBoxOptionOption<string>[]>([]);
    const { selectedView, setSelectedView } = useEasySearchProvider();
    const { colorMode } = useEuiTheme();

    const configurationService = new ConfigurationService(accessToken);

    const [views, setViews] = useState<Array<PersonalView>>([]);

    const { dispatchToast } = useToast();

    const notify = useCallback(
      (toastTitle: string, toastBody: string) => {
        dispatchToast(
          <Toast>
            <ToastTitle>{toastTitle}</ToastTitle>
            <ToastBody>{toastBody}</ToastBody>
          </Toast>,
          { timeout: 3000, intent: 'success' },
        );
      },
      [dispatchToast],
    );

    const closePopover = () => setIsPopoverOpen(false);

    const onChange = (selectedOptions: EuiComboBoxOptionOption<string>[]) => {
      // We should only get back either 0 or 1 options.
      setSelected(selectedOptions);
    };

    const onCreateOption = (searchValue: string, options: EuiComboBoxOptionOption<string>[]) => {
      const normalizedSearchValue = searchValue.trim().toLowerCase();

      if (!normalizedSearchValue) {
        return;
      }

      const newOption = {
        label: searchValue,
      };

      // Select the option.
      setSelected([newOption]);
    };

    const handleSaveView = async () => {
      if (selectedOptions?.length === 0) {
        // Optionally, show an error message for empty view name
        return;
      }

      const selectedOption = selectedOptions![0];

      if (selectedOption.id) {
        return handleUpdateView();
      }

      const personalView: PersonalView = {
        id: selectedOption.id ?? Guid(),
        viewType: ViewTypes.Static,
        label: selectedOption.label,
        userIds: [contactId],
        pageOptions: {
          presenceView: false,
          groupedBy: undefined,
          fullScreen: false,
        },
      };

      try {
        await configurationService.postPersonalView(userId, personalView);

        const updatedUserConfig = await configurationService.getUserConfiguration(userId);

        dispatch({ type: 'SET_USER_CONFIG', payload: updatedUserConfig });
        setViews(updatedUserConfig!.Views);
        //setSelectedView(personalView);

        notify(
          t('Notifications.Titles.View.Created'),
          t('Notifications.Contents.View.Created', {
            label: personalView.label,
          }),
        );

        closePopover();
      } catch (error) {
        console.error('Error saving view:', error);
      }
    };

    const handleUpdateView = async () => {
      if (selectedOptions?.length === 0) {
        // Optionally, show an error message for empty view name
        return;
      }

      const selectedOption = selectedOptions![0];

      if (!selectedOption.id) {
        // Optionally, show an error message for empty view name
        return;
      }

      const updatedSelectedView = userConfig?.Views?.find(v => v.id === selectedOption.id);

      if (!updatedSelectedView) {
        return;
      }

      const updatedView = {
        ...updatedSelectedView,
        userIds: [...updatedSelectedView.userIds ?? [], contactId]
      };

      try {
        const updatedUserConfig = await configurationService.updatePersonalView(userId, updatedView);

        dispatch({ type: 'SET_USER_CONFIG', payload: updatedUserConfig });
        setViews(updatedUserConfig!.Views);
        //setSelectedView(updatedView);

        notify(
          t('Notifications.Titles.View.Updated'),
          t('Notifications.Contents.View.Updated', {
            label: updatedView.label,
          }),
        );
      } catch (error) {
        console.error('Error updating view:', error);
      }
    };

    const handleRemoveContact = async () => {
      if (!selectedView) {
        // Optionally, show an error message for empty view name
        return;
      }

      const updatedView = {
        ...selectedView,
        userIds: selectedView.userIds?.filter(u => u !== contactId) ?? []
      };

      try {
        const updatedUserConfig = await configurationService.updatePersonalView(userId, updatedView);

        dispatch({ type: 'SET_USER_CONFIG', payload: updatedUserConfig });
        setViews(updatedUserConfig!.Views);
        setSelectedView(updatedView);

        notify(
          t('Notifications.Titles.View.Updated'),
          t('Notifications.Contents.View.Updated', {
            label: updatedView.label,
          }),
        );
      } catch (error) {
        console.error('Error updating view:', error);
      }
    };

    useMemo(() => {
      const staticViews = userConfig?.Views.filter(v => v.viewType === ViewTypes.Static);

      const staticViewOptions = staticViews?.map((staticView: PersonalView) => {
        const comboItem: EuiComboBoxOptionOption<string> = {
          label: staticView.label,
          id: staticView.id
        }
        return comboItem;
      })

      setStaticViewOptions(staticViewOptions ?? []);

    }, [userConfig])


    return (
      selectedView?.viewType === ViewTypes.Static ? (
        <Tooltip
          content={t('Views.StaticView.Actions.RemoveContact')}
          relationship="description"
        >
          <Button
            appearance="transparent"
            size="small"
            onClick={() => handleRemoveContact()}
            icon={
              <SubtractCircleRegular />
            } />
        </Tooltip>
      ) : (
        <>
          <EuiPopover
            className={colorMode.toLowerCase()}
            button={
              <Tooltip
                content={t('Views.StaticView.Actions.AddContact')}
                relationship="description"
              >
                <Button
                  appearance="transparent"
                  size="small"
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  icon={
                    <AddCircleRegular />
                  }
                />
              </Tooltip>}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
            anchorPosition="downLeft"
          >
            <EuiPopoverTitle>{t("Views.StaticView.Modal.Title")}</EuiPopoverTitle>
            <EuiFlexGroup direction="column" gutterSize="s" style={{ width: '300px' }}>
              <EuiText size='s'>
                <p>
                  {t("Views.StaticView.Modal.Description")}
                </p>
              </EuiText>
              <EuiComboBox
                className={colorMode.toLowerCase()}
                placeholder={t("Views.StaticView.Modal.Placeholder")}
                singleSelection={{ asPlainText: true }}
                options={staticViewOptions}
                selectedOptions={selectedOptions}
                onChange={onChange}
                onCreateOption={onCreateOption}
                customOptionText={t("Views.StaticView.Modal.CustomOptionText")}
              />
              <EuiFlexItem>

              </EuiFlexItem>

            </EuiFlexGroup>
            <EuiPopoverFooter>
              <EuiButton
                onClick={handleSaveView}
                iconType="save"
                fullWidth
              >
                {t('Button.Save')}
              </EuiButton>
            </EuiPopoverFooter>
          </EuiPopover>
        </>
      )
    );
  },
);
