import {Facet, Sorting, withSearch, WithSearch} from '@elastic/react-search-ui';
import {FilterType, FilterValue} from '@elastic/search-ui';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {useEasyPageOptionsProvider} from '../../contexts/EasyPageOptionsContext';
import EasyCustomFacetView from './EasyCustomFacetView';

type EasySideContentProps = {
  addFilter: (name: string, value: FilterValue, type?: FilterType) => void;
  removeFilter: (name: string, value?: FilterValue, type?: FilterType) => void;
};

type EDFilter = {
  field: string;
  label: string;
  placeholder: string;
};

const EasySideContentViewComponent: React.FC<EasySideContentProps> = ({
  addFilter,
  removeFilter,
}) => {
  const {t} = useTranslation();
  const {pageOptions} = useEasyPageOptionsProvider();
  const isInitialMount = useRef(true);
  const prevWasSearched = useRef(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (pageOptions.presenceView) {
      removeFilter('type');
      addFilter('type', 'entraid', 'any');
    } else {
      removeFilter('type');
    }
  }, [pageOptions.presenceView, addFilter, removeFilter]);

  const sortOptions = [
    { name: t('Sidenav.SortBy.Relevance'), value: [] },
    {
      name: t('Sidenav.SortBy.DisplayName'),
      value: [{ field: 'display_name', direction: 'asc' }],
    },
    {
      name: t('Sidenav.SortBy.GivenName'),
      value: [{ field: 'given_name', direction: 'asc' }],
    },
    {
      name: t('Sidenav.SortBy.Surname'),
      value: [{ field: 'surname', direction: 'asc' }],
    },
    {
      name: t('Sidenav.SortBy.Company'),
      value: [{ field: 'company_name', direction: 'asc' }],
    },
    {
      name: t('Sidenav.SortBy.Department'),
      value: [{ field: 'department', direction: 'asc' }],
    },
  ];

  const filters: EDFilter[] = [
    {
      field: 'type',
      label: 'Sidenav.ContactType.Name',
      placeholder: 'Sidenav.ContactType.Placeholder',
    },
    {
      field: 'tags',
      label: 'Sidenav.Tags.Name',
      placeholder: 'Sidenav.Tags.Placeholder',
    },
    {
      field: 'company_name',
      label: 'Sidenav.Company.Name',
      placeholder: 'Sidenav.ContactType.Placeholder',
    },
    {
      field: 'country',
      label: 'Sidenav.Country.Name',
      placeholder: 'Sidenav.Country.Placeholder',
    },
    {
      field: 'city',
      label: 'Sidenav.City.Name',
      placeholder: 'Sidenav.City.Placeholder',
    },
    {
      field: 'postal_code',
      label: 'Sidenav.PostalCode.Name',
      placeholder: 'Sidenav.PostalCode.Placeholder',
    },
    {
      field: 'office_location',
      label: 'Sidenav.Office.Name',
      placeholder: 'Sidenav.Office.Placeholder',
    },
    {
      field: 'department',
      label: 'Sidenav.Department.Name',
      placeholder: 'Sidenav.Department.Placeholder',
    },
    {
      field: 'job_title',
      label: 'Sidenav.JobTitle.Name',
      placeholder: 'Sidenav.JobTitle.Placeholder',
    },
  ];

  return (
    <WithSearch
      mapContextToProps={({wasSearched}) => ({
        wasSearched,
      })}
    >
      {({wasSearched}) => {
        return (
          <>
            {!pageOptions.fullScreen && (
              <div
                className={
                  pageOptions.presenceView === false ||
                  pageOptions.fullScreen === false
                    ? ' sui-layout-sidebar-standardView'
                    : ' sui-layout-sidebar-presenceView'
                }
              >
                {/* {sourceType && (
                  <div className='main-filter'>
                    <Facet
                      key={sourceType.field}
                      field={sourceType.field}
                      label={t(sourceType.label)}
                      filterType="any"
                      isFilterable={true}
                      view={props => (
                        <CustomFacetView
                          {...props}
                          t={t}
                          field={sourceType.field}
                          placeholder={t(sourceType.placeholder)}
                        />
                      )}
                    />
                  </div>
                )} */}
                {wasSearched && (
                  <Sorting
                    className="main-filter"
                    label={t('Sidenav.SortBy.Name')}
                    sortOptions={sortOptions}
                    // view={props => (
                    //   <EasyCustomSortingView
                    //   {...props} />
                    // )}
                  />
                )}
                {filters.map((filter) => (
                  <Facet
                    key={filter.field}
                    field={filter.field}
                    label={t(filter.label)}
                    filterType="any"
                    isFilterable={true}
                    view={(props) => (
                      <EasyCustomFacetView
                        {...props}
                        t={t}
                        field={filter.field}
                        placeholder={t(filter.placeholder)}
                      />
                    )}
                  />
                ))}
              </div>
            )}
          </>
        );
      }}
    </WithSearch>
  );
};

export const EasySideContentView = withSearch(({addFilter, removeFilter}) => ({
  addFilter,
  removeFilter,
}))(EasySideContentViewComponent);
