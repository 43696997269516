// import '@elastic/eui/dist/eui_theme_light.css';
import { createRoot } from 'react-dom/client';
import { App } from './components/App';
import { EuiProvider } from '@elastic/eui';

import './index.scss';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <EuiProvider>
    <App />
  </EuiProvider>
);
