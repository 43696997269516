import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useEuiTheme,
} from '@elastic/eui';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface DeleteMailboxModalProps {
  isVisible: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  isSaving: boolean;
  label: string;
}

const DeleteMailboxModal: FC<DeleteMailboxModalProps> = ({
  isVisible,
  onClose,
  onDelete,
  isSaving,
  label,
}) => {
  const {t} = useTranslation();
  const { colorMode } = useEuiTheme();

  if (!isVisible) return null;

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Configuration.SharedMailboxes.DeleteModal.Title')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody  className={colorMode.toLowerCase()}>
        {t('Configuration.SharedMailboxes.DeleteModal.Message')} <b>{label}</b>
        <br />
        <br />
        <p><strong>{t('Configuration.SharedMailboxes.DeleteModal.ImportantText')}</strong></p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>
          {t('Configuration.SharedMailboxes.Buttons.Cancel')}
        </EuiButtonEmpty>
        <EuiButton color="danger" onClick={onDelete} isLoading={isSaving}>
          {t('Configuration.SharedMailboxes.Buttons.Confirm')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default DeleteMailboxModal;
