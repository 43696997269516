import { ViewTypes } from "../../common/enums";
import { PersonalView } from "../../common/interfaces";

export const createFavouritesView = (t: (key: string) => string) => (favouriteIds?: string[]): PersonalView => ({
  id: 'favourites_view',
  viewType: ViewTypes.Standard,
  label: t('Views.Dropdown.FavoritesText'),
  searchTemplate: JSON.stringify({
    searchTerm: '',
    filters: [
      {
        field: 'id',
        values: favouriteIds || '',
        type: 'any',
      },
    ],
  })
});

export const createSynchedContactsView = (t: (key: string) => string) => (synchedContactIds?: string[]): PersonalView => ({
  id: 'synched_contacts_view',
  viewType: ViewTypes.Standard,
  label: t('Views.Dropdown.SynchedText'),
  searchTemplate: JSON.stringify({
    searchTerm: '',
    filters: [
      {
        field: 'id',
        type: 'any',
        values: synchedContactIds || '',
      },
    ],
  }),
  pageOptions: {
    presenceView: false,
    groupedBy: undefined,
    fullScreen: false,
  }
});
