import {
  EuiBadge,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import { ResultViewProps } from '@elastic/react-search-ui-views';
import { memo, useRef, useState } from 'react';

import { DeepLinkActionType } from '../../common/enums';
import { StringToColor } from '../../common/helpers';
import { deepLinkHandler } from '../../handlers/deepLinkHandler';
import { EasyCardLine } from '../cards/EasyCardLine';
import { DetailsModal } from '../modals/DetailsModal';
import { EasyUser } from '../EasyUser';
import { FavoriteStar } from '../FavoriteStar';
import { QuickActions } from '../QuickActions';
import { SynchedContact } from '../SynchedContact';
import { SynchedContactRef } from '../../common/interfaces';
import { AddContactToStaticView } from '../AddContactToStaticView';

interface ResultHeaderProps {
  resultView: ResultViewProps;
  onOpenDetailModal: () => void;
  isInDetailModal?: boolean;
  withSubtitle?: boolean
}

export const ResultHeader = memo(
  ({
    resultView,
    onOpenDetailModal,
    isInDetailModal = false,
    withSubtitle = true
  }: ResultHeaderProps) => {
    const synchedContactRef = useRef<SynchedContactRef>(null);
    const onAddSynched = () => { 
      if (synchedContactRef.current) {
        synchedContactRef.current.setIsLoading(true);
        synchedContactRef.current.addSynchedContact();
      }
    };
    return (
      <div className="sui-result__header">
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem grow={false}>
            <EasyUser
              userId={resultView?.result?.id?.raw}
              resultType={resultView?.result?.type?.raw}
              displayName={resultView?.result?.display_name?.raw}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size="xs">
              <h1>{resultView?.result?.display_name?.raw}</h1>
            </EuiTitle>
            {withSubtitle && resultView.result.job_title?.raw && (
              <EuiTitle size="xxxs" className='easy-result-card-subtitle'>
                <h5>{resultView.result.job_title?.raw}</h5>
              </EuiTitle>
            )}
            <QuickActions
              resultView={resultView}
              handleDetailModalOpen={onOpenDetailModal}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" gutterSize="s">
              <EuiFlexItem>
                <AddContactToStaticView contactId={resultView?.result?.id?.raw} />
              </EuiFlexItem>
              <EuiFlexItem>
                <FavoriteStar contactId={resultView?.result?.id?.raw} onAddSynched={onAddSynched} setIsLoading={synchedContactRef.current?.setIsLoading} />
              </EuiFlexItem>
              <EuiFlexItem>
                {resultView?.result?.type?.raw !== 'personal' && (
                  <SynchedContact
                    ref={synchedContactRef}
                    contactId={resultView?.result?.id?.raw}
                    displayName={resultView?.result?.display_name?.raw}
                    synchedContact={resultView?.result}
                  />
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    );
  }
);

ResultHeader.displayName = 'ResultHeader';

export const ResultDetails: React.FC<{ resultView: ResultViewProps }> = ({
  resultView,
}) => (
  // <div className="sui-result__body">
  <EuiFlexGroup direction="column" gutterSize="s">
    {/* Handle company */}
    {resultView.result.company_name?.raw && (
      <EuiFlexItem key={`company`}>
        <EasyCardLine
          iconType="company">
          {resultView.result.company_name?.raw}
        </EasyCardLine>
      </EuiFlexItem>
    )}

    {/* Handle emails */}
    {Array.isArray(resultView.result.mails?.raw) &&
      resultView.result.mails.raw.map((email: string, index: number) => (
        <EuiFlexItem key={`email-${index}`}>
          <EasyCardLine
            iconType="email"
            copyEnabled
            onCopy={() => navigator.clipboard.writeText(email)}
            clickEnabled
            onClick={() => deepLinkHandler(email, DeepLinkActionType.Email)}
          >
            {email}
          </EasyCardLine>
        </EuiFlexItem>
      ))}

    {/* Handle business phones array */}
    {Array.isArray(resultView.result.business_phones?.raw) &&
      resultView.result.business_phones.raw.map(
        (phone: string, index: number) => (
          <EuiFlexItem key={`phone-${index}`}>
            <EasyCardLine
              iconType="phone"
              copyEnabled
              onCopy={() => navigator.clipboard.writeText(phone)}
              clickEnabled
              onClick={() => deepLinkHandler(phone, DeepLinkActionType.PSTNCall)}
            >
              {phone}
            </EasyCardLine>
          </EuiFlexItem>
        ),
      )}

    {/* Handle mobile phone */}
    {resultView?.result?.mobile_phone?.raw && (
      <EuiFlexItem>
        <EasyCardLine
          iconType="mobile"
          copyEnabled
          onCopy={() => navigator.clipboard.writeText(resultView.result.mobile_phone.raw)}
          clickEnabled
          onClick={() => deepLinkHandler(
            resultView.result.mobile_phone.raw,
            DeepLinkActionType.PSTNCall,
          )}
        >
          {resultView?.result?.mobile_phone?.raw}
        </EasyCardLine>
      </EuiFlexItem>
    )}

    {/* Handle address */}
    {resultView?.result?.street_address?.raw && resultView?.result?.city?.raw && (
      <EuiFlexItem>
        <EasyCardLine
          iconType="map"
          clickEnabled
          copyEnabled
          onCopy={() => navigator.clipboard.writeText(`${resultView.result.street_address.raw ?? ""}, ${resultView.result.postal_code?.raw ?? ""} ${resultView.result.city.raw ?? ""}`)}
          onClick={() => deepLinkHandler(
            `${resultView.result.street_address.raw ?? ""}, ${resultView.result.postal_code?.raw ?? ""} ${resultView.result.city.raw ?? ""}`,
            DeepLinkActionType.GoogleMaps,
          )}
        >
          <span>
            {`${resultView?.result?.street_address?.raw ?? ""}, ${resultView.result.postal_code?.raw ?? ""} ${resultView?.result?.city?.raw ?? ""}`}
          </span>
        </EasyCardLine>
      </EuiFlexItem>
    )}

    {/* Handle country */}
    {resultView?.result?.country?.raw && (
      <EuiFlexItem>
        <EasyCardLine
          iconType="map"
        >
          {resultView?.result?.country?.raw}
        </EasyCardLine>
      </EuiFlexItem>
    )}

    {/* Handle tags array */}
    {Array.isArray(resultView.result.tags?.raw) &&
      resultView.result.tags.raw.length > 0 && (
        <>
          <EuiSpacer size="s" />
          <EuiFlexItem>
            <EuiFlexGroup wrap gutterSize="xs">
              {resultView?.result?.tags.raw?.map((tag: string, index: number) => (
                <EuiFlexItem key={`tag-${index}`} grow={false}>
                  <EuiBadge>{tag}</EuiBadge>
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </>
      )}
  </EuiFlexGroup>
  // </div>
);

interface EasyStandardViewProps {
  resultView: ResultViewProps;
}

export const EasyStandardView: React.FC<EasyStandardViewProps> = ({
  resultView,
}) => {
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);

  const handleDetailModalClose = () => setIsDetailModalVisible(false);
  const toggleDetailModalOpen = () => setIsDetailModalVisible(!isDetailModalVisible);

  const type = resultView.result.type?.raw;
  const synchedContactRef = useRef<SynchedContactRef>(null);
  const onAddSynched = () => {
    if (synchedContactRef.current) {
      synchedContactRef.current.setIsLoading(true);
      synchedContactRef.current.addSynchedContact();
    }
  };
  return (
    <>
      {resultView?.result && (
        // <li
        //   className={`sui-result-standard ${type} standardView`}
        //   style={{
        //     width: '100%',
        //     borderLeft: `7px solid ${type === 'entraid'
        //       ? '#6264A7'
        //       : type === 'personal'
        //         ? '#ffa500'
        //         : StringToColor(type)
        //       }`,
        //   }}
        //   key={resultView?.result?.id?.raw}
        // >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            className='easy-searchResult-card'
            key={resultView?.result?.id?.raw}
            // onClick={toggleDetailModalOpen}
            style={{
              width: '100%',
              padding: '0px',
              borderLeft: `7px solid ${type === 'entraid'
                ? '#6264A7'
                : type === 'personal'
                  ? '#ffa500'
                  : StringToColor(type)
                }`,
            }}
            title={
              <EuiFlexGroup alignItems="flexStart">
                <EuiFlexItem grow={false}>
                  <EasyUser
                    userId={resultView?.result?.id?.raw}
                    resultType={resultView?.result?.type?.raw}
                    displayName={resultView?.result?.display_name?.raw}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiTitle size="xs">
                    <h1>{resultView?.result?.display_name?.raw}</h1>
                  </EuiTitle>
                  {resultView.result.job_title?.raw && (
                    <EuiTitle size="xxxs" className='easy-result-card-subtitle'>
                      <h5>{resultView.result.job_title?.raw}</h5>
                    </EuiTitle>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
              // <>
              //   <EasyUser
              //     userId={resultView?.result?.id?.raw}
              //     resultType={resultView?.result?.type?.raw}
              //     displayName={resultView?.result?.display_name?.raw}
              //   />
              //   &nbsp;&nbsp;{resultView?.result?.display_name?.raw}
              //   {resultView?.result.job_title?.raw && (
              //     <EuiTitle size="xxxs" className='easy-result-card-subtitle'>
              //       <h5>{resultView.result.job_title?.raw}</h5>
              //     </EuiTitle>
              //   )}
              //   </>
            }
            description={
              <>
              <QuickActions
                resultView={resultView}
                handleDetailModalOpen={toggleDetailModalOpen}
              />
              <EuiSpacer size='m' />
              </>
            }
            footer={
              <EuiFlexGroup direction="row" gutterSize="l" responsive={false}>
                <EuiFlexItem grow={false}>
                  <AddContactToStaticView contactId={resultView?.result?.id?.raw} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <FavoriteStar contactId={resultView?.result?.id?.raw} onAddSynched={onAddSynched} setIsLoading={synchedContactRef.current?.setIsLoading} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  {resultView?.result?.type?.raw !== 'personal' && (
                    <SynchedContact
                      ref={synchedContactRef}
                      contactId={resultView?.result?.id?.raw}
                      displayName={resultView?.result?.display_name?.raw}
                      synchedContact={resultView?.result}
                    />
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            }
          >
            <>
              <ResultDetails resultView={resultView} />
              <DetailsModal
                resultView={resultView}
                isOpen={isDetailModalVisible}
                onClose={toggleDetailModalOpen}
              />
            </>
          </EuiCard>
        </EuiFlexItem>
        // </li>
      )}
    </>
  );
};
