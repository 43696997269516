import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { FC, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SharedMailbox } from '../../common/interfaces';

interface AddMailboxModalProps {
  isVisible: boolean;
  isLoading: boolean;
  showErrors: boolean;
  mailboxAddress: string;
  isValidEmail: boolean;
  hasDublicates: boolean;
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddMailboxModal: FC<AddMailboxModalProps> = ({
  isVisible,
  isLoading,
  showErrors,
  mailboxAddress,
  isValidEmail,
  hasDublicates,
  onClose,
  onSubmit,
  onChange,
}) => {
  const { t } = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [debouncedValidation, setDebouncedValidation] = useState(true);
  const { colorMode } = useEuiTheme();

  // Handle input changes with debounce
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange(e);

    // Initially set validation to false
    setDebouncedValidation(false);

    // Wait 300ms before executing validation
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setDebouncedValidation(true);
    }, 1000);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (!isVisible) return null;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    await onSubmit(e);
  };

  return (
    <EuiModal aria-labelledby="add-mailbox-modal-title" onClose={onClose}>
      <EuiForm component="form" onSubmit={handleSubmit}>
        <EuiModalHeader>
          <EuiModalHeaderTitle id="add-mailbox-modal-title">
            {t('Configuration.SharedMailboxes.AddButton')}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody className={colorMode.toLowerCase()}>
          <p><strong>{t('Configuration.SharedMailboxes.AddModal.ImportantText')}</strong></p>
          <EuiSpacer size="m" />
          {/* Email input field with validation */}
          <EuiFieldText
            placeholder={t(
              'Configuration.SharedMailboxes.Form.MailboxAddressPlaceholder',
            )}
            aria-label={t(
              'Configuration.SharedMailboxes.Form.MailboxAddressPlaceholder',
            )}
            value={mailboxAddress}
            onChange={handleChange}
            isInvalid={mailboxAddress.length > 0 && (!isValidEmail || hasDublicates) && debouncedValidation}
            data-testid="mailbox-input"
          />
          {/* Error message with debounced validation */}
          {mailboxAddress.length > 0 && (!isValidEmail || hasDublicates) && debouncedValidation && (
            <>
              <EuiSpacer size="s" />
              <EuiText size="s" color="danger">
                {!isValidEmail && t('Configuration.SharedMailboxes.Form.EmailError')}
                {hasDublicates && t('Configuration.SharedMailboxes.Form.EmailHasDuplicate')}
              </EuiText>
            </>
          )}
          <EuiSpacer size="m" />
        </EuiModalBody>

        <EuiModalFooter>
          {/* Cancel button */}
          <EuiButton onClick={onClose} color="text">
            {t('Configuration.SharedMailboxes.Buttons.Cancel')}
          </EuiButton>

          {/* Submit button with validation */}
          <EuiButton
            type="submit"
            fill
            isDisabled={!mailboxAddress || !isValidEmail || hasDublicates}
            isLoading={isLoading}
          >
            {t('Configuration.SharedMailboxes.Buttons.Save')}
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};

export default AddMailboxModal;