import axios from 'axios';

import config from '../common/config';
import { EasyDirectoryUser, EasySearchResult, Facets } from '../common/interfaces';

export class SearchService {
  constructor(access_token?: string) {
    if (access_token !== undefined && access_token !== null) {
      if (access_token.startsWith('Bearer ')) {
        this.access_token = access_token;
      } else {
        this.access_token = 'Bearer ' + access_token;
      }
    }
  }

  access_token: string | undefined;

  async getFavorites(userIds: string[]): Promise<EasyDirectoryUser[] | null> {
    try {
      const queryParams = new URLSearchParams();

      userIds.forEach((userId: string) => {
        queryParams.append('ids[]', userId);
      });

      const response = await axios.get(
        config.apiEndpoint +
        '/easydirectory/contacts?' +
        queryParams.toString(),
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getElasticContactTypes(tenantId: string): Promise<Facets | null> {
    try {

      const response = await axios.post(config.apiEndpoint + '/easydirectory/search/api/as/v1/engines/' + tenantId + '/search.json', {
        query: '',
        facets: {
          type: { type: 'value', size: 30 }
        },
        result_fields: {
          id: {
            raw: {}
          },
        }
      }, {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })

      const result = response.data as EasySearchResult;

      return result.facets;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getElasticDocuments(tenantId: string, documentIds: string[]): Promise<EasyDirectoryUser[] | null> {
    try {

      const response = await axios.post(config.apiEndpoint + '/easydirectory/search/api/as/v1/engines/' + tenantId + '/search.json',
        documentIds,
        {
          headers: {
            'Authorization': this.access_token,
            'Content-Type': 'application/json'
          }
        })

      const result = response.data as EasyDirectoryUser[];

      return result;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getElasticLookupResult(phoneNumber: string, tenantId: string): Promise<EasySearchResult | null> {
    try {

      const response = await axios.post(config.apiEndpoint + '/easydirectory/search/api/as/v1/engines/' + tenantId + '/search.json', {
        query: phoneNumber,
        result_fields: {
          id: {
            raw: {}
          },
          type: {
            raw: {}
          },
          display_name: {
            raw: {}
          },
          given_name: {
            raw: {}
          },
          surname: {
            raw: {}
          },
          mail: {
            raw: {}
          },
          mobile_phone: {
            raw: {}
          },
          business_phones: {
            raw: {}
          },
          job_title: {
            raw: {}
          },
          office_location: {
            raw: {}
          },
          upn: {
            raw: {}
          },
          city: {
            raw: {}
          },
          company_name: {
            raw: {}
          },
          country: {
            raw: {}
          },
          department: {
            raw: {}
          },
          fax_number: {
            raw: {}
          },
          postal_code: {
            raw: {}
          },
          street_address: {
            raw: {}
          },
          tags: {
            raw: {}
          }
        }
      }, {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
