import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui';
import { ResultViewProps } from '@elastic/react-search-ui-views';
import { Text } from '@fluentui/react-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeepLinkActionType } from '../../common/enums';
import { deepLinkHandler } from '../../handlers/deepLinkHandler';
import { ConfigurationService } from '../../services/ConfigurationService';
import { EasyContext } from '../EasyContext';
import { TagManagementSection } from '../tags/TagManagement';
import { ResultHeader } from '../views/EasyStandardView';
import { EasyCardLine } from './EasyCardLine';
import { QuickActions } from '../QuickActions';
import { SearchResult } from '@elastic/search-ui';

interface EasyDetailCardProps {
  resultView: ResultViewProps;
  onTagsChange?: () => void;
}

export const EasyDetailCard: React.FC<EasyDetailCardProps> = ({
  resultView,
  onTagsChange,
}) => {
  const { t } = useTranslation();
  const { tenantConfig, userId, accessToken } = useContext(EasyContext);
  const configurationService = new ConfigurationService(accessToken);

  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const handleDetailModalOpen = () => setIsDetailModalVisible(true);

  useEffect(() => {
    if (resultView.result.tags?.raw) {
      setTags(resultView.result.tags.raw);
    }
  }, []);

  // const handleAddTag = async (tag: string) => {
  //   try {
  //     const assignedTag = tenantConfig!.orgTags?.find(
  //       (orgTag) => orgTag.label === tag,
  //     );

  //     setIsAddingTag(true);

  //     const result = await configurationService.assignTagToUser(
  //       tenantConfig!.TenantId,
  //       resultView.result.id.raw,
  //       assignedTag!,
  //     );

  //     if (result) {
  //       setTags([...tags, tag]);
  //       onTagsChange?.();
  //     }
  //   } catch (error) {
  //     console.error('Error adding tag:', error);
  //   } finally {
  //     setIsAddingTag(false);
  //   }
  // };

  return (
    <>
      <div style={{ width: '100%' }}>
        <QuickActions resultView={resultView} handleDetailModalOpen={handleDetailModalOpen} isInDetailsView />
        <EuiSpacer size="m" />
        <div className="sui-result__body">
          <EuiFlexGroup direction="column" gutterSize="s">
            <EuiFlexItem>
              <EuiText style={{
                color: 'var(--colorNeutralForeground2)'
              }}><b>Details:</b></EuiText>
            </EuiFlexItem>
            {/* Handle department */}
            {resultView.result.department?.raw && (
              <EuiFlexItem key={`department`}>
                <EasyCardLine
                  iconType="department">
                  {resultView.result.department?.raw}
                </EasyCardLine>
              </EuiFlexItem>
            )}
            {/* Handle emails */}
            {resultView.result.mails?.raw.map(
              (email: string, index: number) => (
                <EuiFlexItem key={`email-${index}`}>
                  <EasyCardLine
                    iconType="email"
                    copyEnabled
                    onCopy={() => navigator.clipboard.writeText(email)}
                    clickEnabled
                    onClick={() =>
                      deepLinkHandler(email, DeepLinkActionType.Email)
                    }
                  >
                    {email}
                  </EasyCardLine>
                </EuiFlexItem>
              ),
            )}

            {/* Handle business phones array */}
            {resultView.result.business_phones?.raw.map(
              (phone: string, index: number) => (
                <EuiFlexItem key={`phone-${index}`}>
                  <EasyCardLine
                    iconType="phone"
                    copyEnabled
                    onCopy={() => navigator.clipboard.writeText(phone)}
                    clickEnabled
                    onClick={() =>
                      deepLinkHandler(phone, DeepLinkActionType.PSTNCall)
                    }
                  >
                    {phone}
                  </EasyCardLine>
                </EuiFlexItem>
              ),
            )}

            {/* Handle mobile phone */}
            {resultView.result.mobile_phone?.raw && (
              <EuiFlexItem>
                <EasyCardLine
                  iconType="mobile"
                  copyEnabled
                  onCopy={() =>
                    navigator.clipboard.writeText(
                      resultView.result.mobile_phone.raw,
                    )
                  }
                  clickEnabled
                  onClick={() =>
                    deepLinkHandler(
                      resultView.result.mobile_phone.raw,
                      DeepLinkActionType.PSTNCall,
                    )
                  }
                >
                  {resultView.result.mobile_phone.raw}
                </EasyCardLine>
              </EuiFlexItem>
            )}

            {/* Handle address */}
            {resultView.result.street_address?.raw &&
              resultView.result.city?.raw && (
                <EuiFlexItem>
                  <EasyCardLine
                    iconType="map"
                    clickEnabled
                    copyEnabled
                    onCopy={() => navigator.clipboard.writeText(`${resultView.result.street_address.raw ?? ""}, ${resultView.result.postal_code?.raw ?? ""} ${resultView.result.city.raw ?? ""}`)}
                    onClick={() =>
                      deepLinkHandler(
                        `${resultView.result.street_address.raw ?? ""}, ${resultView.result.postal_code?.raw ?? ""} ${resultView.result.city.raw ?? ""}`,
                        DeepLinkActionType.GoogleMaps,
                      )
                    }
                  >
                    <span>
                      {`${resultView.result.street_address.raw ?? ""}, ${resultView.result.postal_code?.raw ?? ""} ${resultView.result.city.raw ?? ""}`}
                    </span>
                  </EasyCardLine>
                </EuiFlexItem>
              )}

            {/* Handle country */}
            {resultView?.result?.country?.raw && (
              <EuiFlexItem>
                <EasyCardLine
                  iconType="map"
                >
                  {resultView?.result?.country?.raw}
                </EasyCardLine>
              </EuiFlexItem>
            )}

            {/* Handle home address */}
            {resultView.result.home_street_address?.raw &&
              resultView.result.home_city?.raw && (
                <EuiFlexItem>
                  <EasyCardLine
                    iconType="map"
                    clickEnabled
                    copyEnabled
                    onCopy={() => navigator.clipboard.writeText(`${resultView.result.home_street_address.raw ?? ""}, ${resultView.result.home_postal_code?.raw ?? ""} ${resultView.result.home_city.raw ?? ""}`)}
                    onClick={() =>
                      deepLinkHandler(
                        `${resultView.result.home_street_address.raw ?? ""}, ${resultView.result.home_postal_code?.raw ?? ""} ${resultView.result.home_city.raw ?? ""}`,
                        DeepLinkActionType.GoogleMaps,
                      )
                    }
                  >
                    <span>
                      {`${resultView.result.home_street_address.raw ?? ""}, ${resultView.result.home_postal_code?.raw ?? ""} ${resultView.result.home_city.raw ?? ""}`}
                    </span>
                  </EasyCardLine>
                </EuiFlexItem>
              )}

            {/* Handle home country */}
            {resultView?.result?.home_country?.raw && (
              <EuiFlexItem>
                <EasyCardLine
                  iconType="map"
                >
                  {resultView?.result?.home_country?.raw}
                </EasyCardLine>
              </EuiFlexItem>
            )}


            <EuiSpacer size="s" />

            {/* Tags Manager can assign Tags */}
            {tenantConfig?.roles?.some(
              (role) =>
                role.roleName === 'TagsManager' && role.users.includes(userId),
            ) ? (
              <EuiFlexItem grow={false}>
                <TagManagementSection
                  contactId={resultView.result.id?.raw}
                  tags={tags}
                />
              </EuiFlexItem>
            ) : (
              <>
                <EuiSpacer size="s" />
                <EuiFlexItem>
                  <EuiFlexGroup wrap gutterSize="xs">
                    {tags?.map((tag: string, index: number) => (
                      <EuiFlexItem key={`tag-${index}`} grow={false}>
                        <EuiBadge>{tag}</EuiBadge>
                      </EuiFlexItem>
                    ))}
                  </EuiFlexGroup>
                </EuiFlexItem>
              </>
            )}
          </EuiFlexGroup>
        </div>
      </div>
    </>
  );
};
