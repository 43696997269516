import { Avatar, PresenceBadgeStatus, Spinner } from '@fluentui/react-components';
import { useGraphWithCredential } from '@microsoft/teamsfx-react';
import React, { useCallback, useEffect, useState } from 'react';

import { TeamsUserCredentialContext } from '../auth/singletonContext';
import config from '../common/config';
import { EasyUserProps } from '../common/interfaces';

export const EasyUser: React.FC<EasyUserProps> = React.memo(
  ({
    userId,
    displayName,
    resultType,
    presence: externalPresence,
    activity: externalActivity,
    photo: externalPhoto,
  }) => {
    const credential = TeamsUserCredentialContext.getInstance().getCredential();
    if (!credential) throw new Error('TeamsFx SDK is not initialized.');

    const [photoUrl, setPhotoUrl] = useState<string | undefined>(undefined);

    const fetchUserData = useCallback(
      async (graph: any) => {
        if (resultType !== "entraid")
          return null;

        if (!userId && externalPresence && externalActivity && externalPhoto)
          return null;

        let profile = null,
          presence = null;

        try {
          // Batch request for profile and presence
          const batchRequest = {
            requests: [
              { id: '1', method: 'GET', url: `/users/${userId}` },
              { id: '2', method: 'GET', url: `/users/${userId}/presence` },
            ],
          };

          const batchResponse = await graph.api('/$batch').post(batchRequest);
          const profileResponse = batchResponse.responses.find(
            (res: any) => res.id === '1',
          );
          const presenceResponse = batchResponse.responses.find(
            (res: any) => res.id === '2',
          );

          profile = profileResponse?.body || {};
          presence = presenceResponse?.body || {};

          try {
            const photoBlob = await graph
              .api(`/users/${userId}/photo/$value`)
              .get();

            const url = URL.createObjectURL(photoBlob);
            setPhotoUrl(url);
          } catch {
            setPhotoUrl('');
          }
        } catch (e) {
          console.error('Error in batch or photo request', e);
        }

        return { profile, presence };
      },
      [userId, externalPresence, externalActivity, externalPhoto],
    );

    const { loading, error, data } = useGraphWithCredential(fetchUserData, {
      scope: config.easyUserScopes,
      credential: credential,
    });

    useEffect(() => {
      return () => {
        if (photoUrl) {
          URL.revokeObjectURL(photoUrl);
        }
      };
    }, [photoUrl]);

    // Use external props if provided, otherwise fallback to fetched data
    const currentPresence = externalPresence || data?.presence?.availability;
    const currentActivity = externalActivity || data?.presence?.activity;
    const currentPhoto = externalPhoto || photoUrl;

    const parsePresenceStatus = (
      presenceStatus?: string,
    ): PresenceBadgeStatus =>
      presenceStatus
        ? (presenceStatus.toLowerCase() as PresenceBadgeStatus)
        : 'unknown';

    return (
      <>
        {loading && !externalPresence && !externalActivity && !externalPhoto ? (
          <Spinner size="small" />
        ) : (
          <Avatar
            size={48}
            color="navy"
            name={displayName}
            shape="circular"
            badge={(resultType === "entraid") ? {
              outOfOffice:
                currentActivity?.toLowerCase() === 'outofoffice' ||
                currentActivity?.toLowerCase() === 'offwork',
              status: parsePresenceStatus(currentPresence),
            } : undefined}
            image={currentPhoto ? { src: currentPhoto } : undefined}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.userId === nextProps.userId &&
      prevProps.displayName === nextProps.displayName &&
      prevProps.presence === nextProps.presence &&
      prevProps.activity === nextProps.activity &&
      prevProps.photo === nextProps.photo
    );
  },
);
